<template>
    <!-- <v-select :items="list_data" ref="myInput" :value="value" @input="handleInput()" :label="label" :name="name" :rules="formRules"
        outlined dense>
    </v-select> -->

    <v-autocomplete :label="label" 
    :value="value" 
    :name="name" 
    :rules="formRules"
    :loading="setLoading" 
    :disabled="setLoading"
    :readonly="isReadonly"
    :filled="isFilled"
    :solo="isSolo"
    :hideDetails="!isDetail"
     :multiple="isMultiple"
     :class="(isReadonly) ? 'isReadonly':''"
    
    v-model="setModel" @change="setChange"
        @input="handleInput()" :items="list_data" ref="myInput" outlined rounded dense >
    </v-autocomplete>
</template>
<script>
    export default {
        data() {
            return {
                formRules: [],
                content: this.value,
                setModel: null,
                setLoading:false
            };
        },
        methods: {
            setFormRules() {
                let validate = this.validation;
                let tmp_validate = [];
                if (validate) {
                      validate.forEach(rule => {
                        switch (rule) {
                            case 'required':
                                tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                                break;
                        }
                    });
                }
              

                this.formRules = tmp_validate;
            },
            handleInput(e) {
                this.$emit('input', this.$refs.myInput.value)
            },
            setChange() {
                this.$emit('setChange', this.setModel);
            },
            load(type){
                this.setLoading = type
            },
            setValue(id){
                 this.setModel = id
            },
             setValueMulti(list){
                let dataAll = this.list_data
                let tmpData = [];
                this.setModel = [];
                tmpData.push(dataAll[0].value)

                this.setModel = tmpData;
            },
            selectAll() {
                let dataAll = this.list_data
                let tmpData = [];
                dataAll.forEach(v => {
                    tmpData.push(v.value)
                });
                this.setModel = tmpData;
            },
            selectFirst() {
                let dataAll = this.list_data
                let tmpData = [];
                this.setModel = [];
                tmpData.push(dataAll[0].value)

                this.setModel = tmpData;
            },
            setValueIndex(pagging) {
                let dataAll = this.list_data
                let tmpData = [];
                this.setModel = [];
                let setPagging = pagging - 1

                tmpData.push(dataAll[setPagging].value)

                this.setModel = tmpData;
            },
            removeAll() {
                this.setModel = [];
            },
            getValue(){
                return this.setModel
            }

        },
        mounted() {
            this.setFormRules();
        },
        created(){
             this.setModel = this.value
          
        },
        props: ['label', 'name', 'value', 'validation', 'list_data','isFilled','isMultiple','isSolo','isDetail','isReadonly'],
        name: 'formInput',
    }
</script>